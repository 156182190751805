import { usePublicPages_ContactUser } from '@collective/data-type';
import {
  Button,
  FieldTextArea,
  FieldTextInput,
  Flex,
  Form,
  isEmail,
  isRequired,
  Text,
} from '@collective/ui';
import { useAnimatedMultiStepLayer } from '@collective/ui-smart';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PublicUserType } from '~/public-pages/types/user';

import { ContactModalStep } from './types';

type ContactModalFormProps = {
  user: PublicUserType;
  onClose: VoidFunction;
};

type ContactModalFormType = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
};

export const ContactModalForm = ({ user, onClose }: ContactModalFormProps) => {
  const { t } = useTranslation();

  const { transitionToStep } = useAnimatedMultiStepLayer<ContactModalStep>();

  const { mutateAsync, isLoading } = usePublicPages_ContactUser();

  const form = useForm<ContactModalFormType>({ mode: 'onSubmit' });

  const { control, handleSubmit, register } = form;

  const onSubmit = async (values: ContactModalFormType) => {
    await mutateAsync({ data: { ...values, userId: user.id } });

    transitionToStep(ContactModalStep.Success);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="24px" p="20px">
        <Text>{t('profile.contact.step.form.description')}</Text>

        <Flex gap="24px">
          <FieldTextInput
            {...register('firstname', { validate: isRequired() })}
            control={control}
            label={t('profile.contact.step.form.field.firstname.label')}
            placeholder={t(
              'profile.contact.step.form.field.firstname.placeholder'
            )}
          />
          <FieldTextInput
            {...register('lastname', { validate: isRequired() })}
            control={control}
            label={t('profile.contact.step.form.field.lastname.label')}
            placeholder={t(
              'profile.contact.step.form.field.lastname.placeholder'
            )}
          />
        </Flex>

        <Flex>
          <FieldTextInput
            {...register('email', { validate: isEmail() })}
            control={control}
            label={t('profile.contact.step.form.field.email.label')}
            placeholder={t('profile.contact.step.form.field.email.placeholder')}
          />
        </Flex>

        <Flex>
          <FieldTextArea
            name="message"
            control={control}
            rules={{ validate: isRequired() }}
            label={t('profile.contact.step.form.field.message.label')}
            placeholder={t(
              'profile.contact.step.form.field.message.placeholder',
              { firstname: user.firstname }
            )}
            minHeight="200px"
          />
        </Flex>

        <Flex gap="8px" justify="flex-end">
          <Button onClick={onClose} variant="secondary" isDisabled={isLoading}>
            {t('profile.contact.step.form.button.close')}
          </Button>
          <Button type="submit" isLoading={isLoading}>
            {t('profile.contact.step.form.button.submit')}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
