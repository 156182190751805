import { Avatar, Flex, Modal, Text } from '@collective/ui';
import {
  AnimatedMultiStepLayer,
  AnimatedStep,
  useAnimatedMultiStepLayer,
} from '@collective/ui-smart';
import { displayName } from '@collective/utils/shared';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicUserType } from '~/public-pages/types/user';

import { ContactModalForm } from './contact-modal-form';
import { ContactModalSuccess } from './contact-modal-success';
import { ContactModalStep } from './types';

type ProfileContactModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  user: PublicUserType;
};

export const ProfileContactModal = ({
  isOpen,
  onClose,
  user,
}: ProfileContactModalProps) => {
  return (
    <AnimatedMultiStepLayer<ContactModalStep, ProfileContactModalLayoutProps>
      isOpen={isOpen}
      steps={Object.values(ContactModalStep)}
      initialStep={ContactModalStep.Form}
      shouldUnmountStep
      layoutProps={{ isOpen, onClose, user }}
      Layout={ProfileContactModalLayout}
    >
      <AnimatedStep step={ContactModalStep.Form}>
        <ContactModalForm user={user} onClose={onClose} />
      </AnimatedStep>
      <AnimatedStep step={ContactModalStep.Success}>
        <ContactModalSuccess user={user} onClose={onClose} />
      </AnimatedStep>
    </AnimatedMultiStepLayer>
  );
};

type ProfileContactModalLayoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: VoidFunction;
  user: PublicUserType;
};

const ProfileContactModalLayout = ({
  children,
  isOpen,
  onClose,
  user,
}: ProfileContactModalLayoutProps) => {
  const { t } = useTranslation();

  const { currentStep } = useAnimatedMultiStepLayer<ContactModalStep>();

  const name = displayName(user);

  const title = (
    <Flex gap={2} align="center">
      <Avatar
        src={user.pictureUrl}
        name={name}
        showBorder
        borderColor="rythm.900"
      />
      <Text variant="desktop-m-bold">
        {currentStep === ContactModalStep.Form
          ? t('profile.contact.step.form.title', { name })
          : t('profile.contact.step.success.title')}
      </Text>
    </Flex>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" title={title}>
      {children}
    </Modal>
  );
};
