import { Flex, IconCollectiveShortLogo } from '@collective/ui';
import { COLLECTIVE_WEBSITE_URL } from '@collective/utils/helpers';

import { PublicUserType } from '~/public-pages/types/user';

import { ProfileContactButton } from './profile-contact-button';

type ProfileHeaderProps = {
  user: PublicUserType;
};

export const ProfileHeader = ({ user }: ProfileHeaderProps) => {
  return (
    <Flex
      position="sticky"
      top={0}
      zIndex={2}
      align="center"
      justify="space-between"
      py="24px"
      px={{ base: '20px', md: '40px' }}
      bg="white"
      borderBottom="1px solid"
      borderColor="rythm.300"
    >
      <a href={COLLECTIVE_WEBSITE_URL}>
        <IconCollectiveShortLogo />
      </a>

      <ProfileContactButton user={user} />
    </Flex>
  );
};
