import { Button, ButtonProps, IconSending, useBoolean } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { PublicUserType } from '~/public-pages/types/user';

import { ProfileContactModal } from './contact-modal';

type ProfileContactButtonProps = {
  user: PublicUserType;
  size?: ButtonProps['size'];
};

export const ProfileContactButton = ({
  user,
  size,
}: ProfileContactButtonProps) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useBoolean(false);

  return (
    <>
      <Button onClick={setOpen.on} rightIcon={<IconSending />} size={size}>
        {t('profile.button')}
      </Button>

      <ProfileContactModal isOpen={isOpen} onClose={setOpen.off} user={user} />
    </>
  );
};
