import { Box, Flex } from '@collective/ui';
import {
  SoloApplicationView,
  UserPills,
  UserSkills,
} from '@collective/ui-smart';
import { getPublicProfileLink } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useRef } from 'react';

import { backendClient } from '~/public-pages/clients/backend-client';
import { launchDarklyClient } from '~/public-pages/clients/launchdarkly-client';
import { Footer } from '~/public-pages/components/footer/footer';
import { ProfileContactButton } from '~/public-pages/components/profile/profile-contact-button';
import { ProfileHeader } from '~/public-pages/components/profile/profile-header';
import {
  DATADOG_PUBLIC_PAGES_APPLICATION_ID,
  DATADOG_SERVICE,
} from '~/public-pages/constants/datadog';
import { GOOGLE_TAG_MANAGER_ID } from '~/public-pages/constants/gtm';
import { collectiveNotFoundRedirect } from '~/public-pages/router';
import { PublicUserType } from '~/public-pages/types/user';
import { getForwardedHeaders } from '~/public-pages/utils/client-helpers';

type ProfileProps = {
  user: PublicUserType;
  slug: string;
};

type ProfileInputProps = {
  slug: string;
};

export const getServerSideProps: GetServerSideProps<
  ProfileProps,
  ProfileInputProps
> = async ({ req, params }) => {
  if (!params?.slug) {
    return collectiveNotFoundRedirect();
  }

  const headers = getForwardedHeaders(req);

  const [{ user }, featureFlags] = await Promise.all([
    backendClient.getPublicUser(params.slug, { headers }),
    launchDarklyClient.getFeatureFlags(),
  ]);

  if (!user) {
    return collectiveNotFoundRedirect(params.slug);
  }

  return {
    props: {
      gtmId: GOOGLE_TAG_MANAGER_ID,
      datadogService: DATADOG_SERVICE,
      datadogApplicationId: DATADOG_PUBLIC_PAGES_APPLICATION_ID,
      initialFeatureFlags: featureFlags,
      language: user.language,
      user,
      slug: params.slug,
    },
  };
};

const Profile = ({ user, slug }: ProfileProps) => {
  const ref = useRef(null);
  const url = getPublicProfileLink(slug);
  const title = `${displayName(user)}${
    user.profile?.title ? ` - ${user.profile.title}` : ''
  }`;
  const description = user.profile?.intro || undefined;

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          url,
          title,
          description,
          images: [
            ...(user.pictureUrl
              ? [
                  {
                    url: user.pictureUrl,
                    width: 92,
                    height: 92,
                    alt: title,
                    type: 'image/png',
                  },
                ]
              : []),
          ],
          siteName: title,
        }}
      />
      <Flex ref={ref} minH="100%" direction="column" bg="rythm.100">
        <ProfileHeader user={user} />

        <SoloApplicationView
          type="public-pages"
          motionEnabled={false}
          user={user}
          parentScrollContainer={ref}
          showWorkExperienceSection
          showEducationSection
          additionalTags={
            <>
              <UserPills user={user} />
              <UserSkills showAllSkills skills={user.skills} />
            </>
          }
          cta={
            <Box display={{ base: 'none', md: 'block' }}>
              <ProfileContactButton user={user} size="lg" />
            </Box>
          }
          updateQueryParamForPortfolio
        />

        <Footer />
      </Flex>
    </>
  );
};

export default Profile;
