import {
  Box,
  Flex,
  Heading,
  IconCollectiveLongLogo,
  Link,
  Text,
} from '@collective/ui';
import { COLLECTIVE_WEBSITE_URL } from '@collective/utils/helpers';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Flex
      mt="auto"
      py={{ base: '40px', md: '80px' }}
      px={{ base: '20px', md: '40px' }}
      justify="center"
      bg="white"
      borderTop="1px solid"
      borderColor="rythm.200"
    >
      <Flex
        w="100%"
        maxW="920px"
        gap="40px"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box>
          <a href={COLLECTIVE_WEBSITE_URL}>
            <IconCollectiveLongLogo w="132px" mb={2} />
          </a>
          <Text color="rythm.600">
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </Text>
        </Box>
        <Flex
          gap={{ base: '24px', md: '40px' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <LinkSection
            title={t('footer.section.freelancers.title')}
            links={[
              {
                name: t('footer.section.freelancers.link1.name'),
                href: t('footer.section.freelancers.link1.url'),
              },
              {
                name: t('footer.section.freelancers.link2.name'),
                href: t('footer.section.freelancers.link2.url'),
              },
              {
                name: t('footer.section.freelancers.link3.name'),
                href: t('footer.section.freelancers.link3.url'),
              },
            ]}
          />
          <LinkSection
            title={t('footer.section.enterprises.title')}
            links={[
              {
                name: t('footer.section.enterprises.link1.name'),
                href: t('footer.section.enterprises.link1.url'),
              },
              {
                name: t('footer.section.enterprises.link2.name'),
                href: t('footer.section.enterprises.link2.url'),
              },
              {
                name: t('footer.section.enterprises.link3.name'),
                href: t('footer.section.enterprises.link3.url'),
              },
            ]}
          />
          <LinkSection
            title={t('footer.section.about.title')}
            links={[
              {
                name: t('footer.section.about.link1.name'),
                href: t('footer.section.about.link1.url'),
              },
              {
                name: t('footer.section.about.link2.name'),
                href: t('footer.section.about.link2.url'),
              },
              {
                name: t('footer.section.about.link3.name'),
                href: t('footer.section.about.link3.url'),
              },
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

type LinkSectionProps = {
  title: string;
  links: { name: string; href: string }[];
};

const LinkSection = ({ title, links }: LinkSectionProps) => {
  return (
    <Flex direction="column" gap="8px">
      <Heading variant="desktop-m-bold">{title}</Heading>

      {links.map(({ name, href }) => (
        <Link key={name} href={href} textDecoration="none">
          <Text color="rythm.600" variant="desktop-m-medium">
            {name}
          </Text>
        </Link>
      ))}
    </Flex>
  );
};
