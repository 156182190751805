import {
  Box,
  Button,
  Flex,
  Heading,
  IconArrowRight,
  Image,
  Text,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { default as pictureLetterMailInTheBox } from '~/public-pages/components/icons/picture-letter-in-the-mailbox.svg';
import { PublicUserType } from '~/public-pages/types/user';

type ContactModalSuccessProps = {
  onClose: VoidFunction;
  user: PublicUserType;
};

export const ContactModalSuccess = ({
  onClose,
  user,
}: ContactModalSuccessProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box pt="24px" bg="primary.25" border="1px solid" borderColor="rythm.200">
        <Image src={pictureLetterMailInTheBox} mx="auto" />
      </Box>
      <Box p="24px 20px 20px 20px">
        <Box>
          <Heading variant="desktop-m-bold" textAlign="center" mb={2}>
            {t('profile.contact.step.success.header', {
              firstname: user.firstname,
            })}
          </Heading>
          <Text
            variant="desktop-m-regular"
            color="rythm.700"
            textAlign="center"
          >
            {t('profile.contact.step.success.content')}
          </Text>
        </Box>
        <Flex justifyContent="flex-end" gap={2} mt={6}>
          <Button onClick={onClose} rightIcon={<IconArrowRight />}>
            {t('profile.contact.step.success.button')}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
